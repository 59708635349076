<template lang="pug">
    layout_page
        section.header.about_header.font_smooth
            .bg.image
            .column.flex_content-vertical.ai_center.jc_center
                .structure
                .margin-bottom--md.anime.fadeInBottom.one
                    h1.text--xxxl.text--uppercase.text--ls-sm Experiencia #[span &] Calidad
                .anime.fadeInBottom.two
                    p.text--uppercase.text--ls-sm Contamos con un gran equipo profesional
            i.scroll_marker.anime.fadeInBottom.three
        include_knowUs
        section.skilled
            .column
                .grid.grid-phi
                    div
                        .wrapper
                            .sec_title
                                span 02
                                h3 Habilidades
                            .margin-bottom--xxl
                                h2.text--xxxl Expertos listos para servirte
                            p Comprometidos en desarrollar cualquier proyecto de construcción, modificación y reparación de estructuras metálicas, de manera eficiente, inteligente y con un acompañamiento cercano a nuestros clientes. Contamos con mano de obra calificada, maquinaria y equipo, idóneos para cumplir con cualquier reto que nos enfrentemos.
                    div.flex_content-vertical.jc_center
                        .wrapper
                            .grid_skilled.flex_content-horizontal
                                .skill
                                    .skill--frame
                                        .frame--wrapper
                                            .margin-bottom--sm
                                                strong.text--sm.text--uppercase #[span.number 27] Unidades
                                            p.text--xs Contamos con grúas, titanes, camiones, excavadora y retroexcavadora.
                                .skill
                                    .skill--frame
                                        .frame--wrapper
                                            .margin-bottom--sm
                                                strong.text--sm.text--uppercase #[span.number 65] Personal
                                            p.text--xs Ponemos a tu servicio personal altamente capacitado y experimentado.
                                .skill
                                    .skill--frame
                                        .frame--wrapper
                                            .margin-bottom--sm
                                                strong.text--sm.text--uppercase #[span.number 117] Proyectos
                                            p.text--xs Tan sólo en los últimos 3 años, con la experiencia recabada durante años de arduo trabajo.
        section.origins
            .bg.image
            .column.flex_content-vertical.jc_center
                .sec_title.sec_title--center
                    span 03
                    h3 Historia
                .margin-bottom--xxl
                    h2.text--xxxl Camino de arduo trabajo
                p Foto del archivo: Nuestro equipo de trabajo. #[br] #[span.text--xs 28 de febrero del año 2006]
        section.philosophy
            .column
                .grid.grid-split
                    div
                        .wrapper
                            .sec_title
                                span 04
                                h3 Misión
                            p Proporcionar servicios de fabricación y montaje de Estructuras Metálicas y Grúas, con recursos técnicos y humanos competentes, mejorando cada día nuestros procedimientos y capacitándonos constantemente.
                    div
                        .wrapper
                            .sec_title
                                span 05
                                h3 Visión
                            p Construir una empresa de prestigio, a través de la eficiencia, calidad, puntualidad, confiabilidad y perseverancia, afines a nuestras aspiraciones personales y de equipo, basados en la honradez, respeto, lealtad, justicia y amor.
        section.partners
            .column
                .sec_title.sec_title--center
                    span 06
                    h3 Alianzas
                .grid.grid-split
                    .partner.hercules
                        .partner--frame
                            .margin-bottom--lg
                                a(href="https://gruashercules.mx/" alt="Grúas Hércules" target="_blank").partner--logo
                                  img(src="@/assets/img/partners/hércules.svg" alt="Grúas Hércules")
                                  span.off_screen Hércules, Grúas y Maniobras
                            p.margin-bottom--lg Las mejores máquinas, especializadas para levantar y mover cargas pesadas.
                            a(href="https://gruashercules.mx/" alt="Grúas Hércules" target="_blank").btn.btn--secondary
                              span Visitar sitio
                    .partner.prefabricaceros
                        .partner--frame
                            .margin-bottom--lg
                                a(href="http://prefabricaceros.com/" alt="Prefabricaceros" target="_blank").partner--logo
                                    img(src="@/assets/img/partners/prefabricaceros.svg" alt="Prefabricaceros")
                                    span.off_screen Prefabricaceros, Materiales de Acero
                            p.margin-bottom--lg Dedicados a la fabricación y comercialización de láminas y sus accesorios.
                            a(href="http://prefabricaceros.com/" alt="Prefabricaceros" target="_blank").btn.btn--base
                              span Visitar sitio
                        

</template>

<script>
import Page from '@/views/layouts/Page'
import anime from 'animejs'
import KnowUs from '@/views/includes/KnowUs'

export default {
    name: 'page_about',
    components: {
        layout_page: Page,
        include_knowUs: KnowUs
    },
    metaInfo() {
        return {
            title: 'Nosotros',
            meta:[
                {vmid: 'dtn', name:'description', content: 'Contamos orgullosamente con 28 años al servicio de nuestros clientes, ayudando a materializar cada una de sus ideas.'},
                {vmid: 'kw', name:'keywords', content: 'La fragua misión, visión, alianzas de la fragua, hércules, prefabricaceros, conócenos, nosotros'},
                {vmid: 'inm', itemprop:'name', content: 'La Fragua | Nosotros'},
                {vmid: 'idtn', itemprop:'description', content: 'Contamos orgullosamente con 28 años al servicio de nuestros clientes, ayudando a materializar cada una de sus ideas.'},
                {vmid: 'ptt', property:'og:title', content: 'La Fragua | Nosotros'},
                {vmid: 'purl', property:'og:url', content: 'https://lafragua.mx/nosotros'},
                {vmid: 'pdtn', property:'og:description', content: 'Contamos orgullosamente con 28 años al servicio de nuestros clientes, ayudando a materializar cada una de sus ideas.'},
                {vmid: 'tdtn', name:'twitter:description', content: 'Contamos orgullosamente con 28 años al servicio de nuestros clientes, ayudando a materializar cada una de sus ideas.'},
                {vmid: 'twtt', name:'twitter:title', content: 'La Fragua | Nosotros'}
            ]
        }
    },
    methods: {
        initAnimations(curtain_shown) {
            let el = this.$el
            let timeline = anime.timeline()
            setTimeout(() => {
                if (!curtain_shown) {
                    timeline.add({
                        targets: el.querySelector('section.header'),
                        scale: [0.8, 1],
                        easing: 'easeOutExpo'
                    })
                }
                timeline
                .add({
                    targets: el.querySelector('.anime.fadeInBottom.one'),
                    translateY: [30, 0],
                    opacity: [0, 1],
                    easing: 'easeOutExpo'
                })
                .add({
                    targets: el.querySelector('.anime.fadeInBottom.two'),
                    translateY: [30, 0],
                    opacity: [0, 1],
                    easing: 'easeOutExpo'
                })
                .add({
                    targets: el.querySelector('.anime.fadeInBottom.three'),
                    translateY: [30, 0],
                    opacity: [0, 1],
                    easing: 'easeOutExpo'
                })
            }, curtain_shown ? 0 : 2400)
        }
    },
    mounted() {
        this.initAnimations(this.$store.getters.isCurtainShown)
        this.$store.commit('desactiveCurtain')
    },
    watch: {
        '$route' (to, from) {
            if (to.name == 'page_about') {
                this.initAnimations(true)
            }
        }
    }
}
</script>